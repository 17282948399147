import React, { useRef, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import { RichText } from 'prismic-reactjs'
import MainLayoutPart from '../components/MainLayoutPart'
import styled, { createGlobalStyle } from 'styled-components'

const ScrollbarStyles = createGlobalStyle`
  /* Scrollbar insgesamt */
  ::-webkit-scrollbar {
    width: 10px; /* Breite des Scrollbalkens */
  }

  /* Scrollbar Track (Hintergrund) */
  ::-webkit-scrollbar-track {
    background: rgb(124, 112, 161); /* Hintergrundfarbe */
  }

  /* Scrollbar Thumb (beweglicher Teil) */
  ::-webkit-scrollbar-thumb {
    background: gray; /* Farbe des Daumens */
    border-radius: 5px; /* Abrundung der Ecken */
  }

  /* Scrollbar Thumb beim Hovern */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Farbe beim Hovern */
  }
`

export const ImageAndText = ({ slice }) => {
  console.log('ImageAndText Content', slice)

  const textColRef = useRef(null)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (textColRef.current) {
        const scrollStep = 50

        if (event.key === 'ArrowUp') {
          textColRef.current.scrollTop -= scrollStep
        } else if (event.key === 'ArrowDown') {
          textColRef.current.scrollTop += scrollStep
        }
      }
    }

    if (textColRef.current) {
      textColRef.current.addEventListener('keydown', handleKeyDown)

      return () => {
        textColRef.current?.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [])

  const phoneLandscape = slice.primary.picture.thumbnails.phone_landscape.url
  const phone = slice.primary.picture.url
  const laptop = slice.primary.picture.thumbnails.laptop.url
  const tablet = slice.primary.picture.thumbnails.tablet.url
  const topPageBanner = slice.primary.page_top_banner
  const bildRechtsTextLinks = !slice.primary.bild_rechts_text_links
  const textCenter = slice.primary.text_zentriert || false
  console.log('textCenter', textCenter)

  const getColOrder = () => {
    const imageCol = (
      <Col sm={6} className="image-col">
        <picture>
          <source media="(min-width: 992px)" srcSet={laptop}></source>
          <source media="(min-width: 768px)" srcSet={tablet}></source>
          <source media="(min-width: 576px)" srcSet={phoneLandscape}></source>
          <img
            src={phone}
            className="d-block w-100"
            width="100%"
            height="100%"
            alt={slice.primary.picture.alt}
          />
        </picture>
      </Col>
    )

    const textCol = (
      <Col
        sm={6}
        className={`text-col ${
          textCenter ? 'center-text-box' : 'left-align-text-box'
        }`}
        ref={textColRef}
        tabIndex="0"
      >
        <div className={`center-text ${textCenter ? '' : 'text-left'}`}>
          <RichText render={slice.primary.title.raw} />
          <RichText render={slice.primary.text.raw} />
        </div>
      </Col>
    )

    return bildRechtsTextLinks ? [textCol, imageCol] : [imageCol, textCol]
  }

  const imageAndTextUi = (
    <SpecRow topPageBanner={topPageBanner}>{getColOrder()}</SpecRow>
  )

  return (
    <>
      <ScrollbarStyles />
      {topPageBanner ? (
        imageAndTextUi
      ) : (
        <MainLayoutPart content={imageAndTextUi} />
      )}
    </>
  )
}

export const query = graphql`
  fragment PrismicPageDataBodyBildUndText on PrismicPageDataBodyBildUndText {
    slice_type
    primary {
      page_top_banner
      text_zentriert
      picture {
        alt
        url(imgixParams: { crop: "top", fit: "crop", h: 600, q: 40 })
        thumbnails {
          laptop {
            url(imgixParams: { q: 40 })
          }
          phone_landscape {
            url(imgixParams: { q: 40 })
          }
          tablet {
            url(imgixParams: { crop: "top", fit: "crop", h: 750, q: 40 })
          }
        }
      }
      text {
        raw
      }
      title {
        raw
      }
    }
  }
  fragment PrismicHomepageDataBodyBildUndText on PrismicHomepageDataBodyBildUndText {
    slice_type
    primary {
      picture {
        alt
        url(imgixParams: { crop: "top", fit: "crop", h: 600, q: 40 })
        thumbnails {
          laptop {
            url(imgixParams: { q: 40 })
          }
          phone_landscape {
            url(imgixParams: { q: 40 })
          }
          tablet {
            url(imgixParams: { crop: "top", fit: "crop", h: 750, q: 40 })
          }
        }
      }
      text {
        raw
      }
      title {
        raw
      }
    }
  }
`

const SpecRow = styled(Row)`
  // Extra small devices (portrait phones, less than 576px)
  // No media query for xs since this is the default in Bootstrap
  margin-top: ${(props) => (props.topPageBanner ? '45px' : '100px')};

  p {
    font-size: 1.2rem !important;
  }

  .text-left {
    text-align: left !important;
  }

  .image-col {
    padding: 0;
    height: 50vh;
    overflow: hidden; // Verhindert, dass das Bild über den Container hinausragt
    img {
      width: 100%;
      height: 100%;
      object-fit: contain; // oder 'cover', je nach Bedarf
    }
  }

  .text-col,
  .image-col {
    background: var(--page-bg-color-dark);
  }

  .text-col {
    padding: 2rem;
  }

  .center-text-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }

  .left-align-text-box {
    justify-content: left;
  }

  .center-text {
    text-align: center;
  }

  // Small devices (landscape phones, 576px and up, col-sm)
  @media (min-width: 576px) {
  }
  // Medium devices (tablets, 768px and up, col-md)
  @media (min-width: 768px) {
  }
  // Large devices (desktops, 992px and up, col-lg)
  @media (min-width: 992px) {
    margin-left: -28px;
    margin-right: -28px;
    p {
      font-size: 1.8rem !important;
    }
    .text-col {
      padding: 3rem 3rem 3rem 4rem;
      height: 50vh; /* Stelle sicher, dass die Text-Spalte 50vh hoch ist */
      overflow-y: auto; /* Vertikales Scrollen bei Bedarf */
    }

    margin-top: ${(props) => (props.topPageBanner ? '71px' : '0')};
  }
  // Extra large devices (large desktops, 1200px and up, col-xl)
  @media (min-width: 1200px) {
    p {
      font-size: 2.4rem;
    }
  }
`
